* {
  text-transform: uppercase !important;
}

th, td {
  word-wrap: break-word;
}

#input-with-text-normal {
  text-transform: none !important;
}

.area-title {
  background: #013494;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: auto !important;
  margin-top: 20px;
}

.grid-title {
  text-align: center !important;
  color: #fff !important;
  padding: 10px !important;
}

.title {
  text-transform: uppercase !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}

.button-register {
  background: #08a128ed !important;
  color: #fff !important;
  margin: 10px !important;
  transition: background-color 0.2s !important;
}

.button-register:hover {
  background: #07781fed !important;
}

.button-back {
  background: #b90b0b !important;
  color: #fff !important;
  margin: 10px !important;
  transition: background-color 0.2s;
}

.button-back:hover {
  background: #b41111 !important;
}

.btnPrimary {
  background-color: #08318e !important;
  color: #fff !important;
  margin: 5px !important;
}

.boxInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.boxInfoTwo {
  border: 1px solid #08318e !important;
  padding: 5px !important;
  border-radius: 4px !important;
  margin-bottom: 5px;
}

.textInfo {
  text-align: left !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #013494 !important;
}

.fontInfo {
  text-align: left !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #414141 !important;
}

.obsInfo {
  margin-top: 10px !important;
  border: 1.7px solid #013494 !important;
  border-radius: 2px !important;
  background: #d1d1d1ed !important;
  padding: 10px !important;
}

.textObsInfo {
  color: #013494 !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
}

/* Depois atualizar essas linhas */
.area-components {
  background: #fff;
  padding: 10px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.grid-campos {
  padding: 10px;
}

.grid-unidade {
  padding: 10px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.select-unidade {
  max-width: 20rem;
}

.add-unidade {
  border: 2px solid #08318e !important;
  max-width: 2rem !important;
  max-height: 2rem !important;
  color: #08318e !important;
}

.header-dialog-cad-unidade {
  background: #08318e !important;
  color: #fff !important;
  font-family: Roboto;
  font-size: 1.5rem;
}

.footer-dialog-cad-unidade {
  display: flex !important;
  justify-content: center !important;
}

.textareaStyle {
  width: 100% !important;
  resize: none !important;
  font-family: Arial, Helvetica, sans-serif !important;
  border-radius: 4px !important;
  padding: 15px !important;
  font-size: 15px !important;
  border: 1px solid #bbb;
}

.textareaStyle:focus {
  outline-color: #08318e !important;
  border-radius: 4px !important;
}

.p-paginator.p-component.p-unselectable-text.p-paginator-bottom {
  display: relative !important;
}

.p-dropdown.p-component.p-inputwrapper-filled {
  position: absolute !important;
  left: 0 !important;
}

.p-paginator-current {
  position: absolute !important;
  right: 0 !important;
}

/* Até aqui */

@media (max-width: 700px) {
  .area-title {
    background: #08318e;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: auto !important;
    width: 100% !important;
    margin: 0 auto !important;
  }

  .area-components {
    background: #fff;
    padding: 10px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 90% !important;
    margin: 0 auto !important;
  }

  .select-unidade {
    max-width: 14rem;
  }
}
